import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import CardColumns from 'react-bootstrap/CardColumns'

import Button from 'react-bootstrap/Button'

import Layout from '../components/layout'
import Product from '../components/product'

export default class Shop extends React.Component {
  render() {
    const products = get(this, 'props.data.allContentfulProduct.edges')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Buy the famous ${siteTitle} Starter Kit today! Multiplication made easy, Number Blocks, Playing Cards, and more.`
    return (
      <Layout pageTitle="Shop" description={metaDescription}>
        <CardColumns className="d-flex flex-wrap justify-content-around">
          {products.map(({ node }, i) => (
            <Product key={i} node={node} />
          ))}
        </CardColumns>
        <hr />
        <h2>Customer Service</h2>
        <Button as={Link} to="/customer-service/">
          Contact Support
        </Button>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulProduct(sort: { fields: price, order: DESC }) {
      edges {
        node {
          checkoutLink
          images {
            title
            fluid(maxHeight: 200) {
              ...GatsbyContentfulFluid
            }
          }
          price
          slug
          title
        }
      }
    }
  }
`
