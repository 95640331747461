import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const Product = ({ node, ...rest }) => {
  const price = `$${~~(node.price / 100)}.${(node.price % 100)
    .toString()
    .padStart(2, 0)}`
  const shopUrl = `/shop/${node.slug}/`
  return (
    <Card style={{ width: '180px' }} {...rest}>
      <Link to={shopUrl}>
        {node.images && node.images[0] && (
          <Card.Img
            as={Img}
            style={{ height: '180px' }}
            fluid={node.images[0].fluid}
            alt={node.images[0].title}
          />
        )}
      </Link>
      <Card.Body>
        <Card.Title>
          <Link to={shopUrl}>{node.title}</Link>
        </Card.Title>
        <Card.Text>{price}</Card.Text>
        {node.checkoutLink ? (
          <Button href={node.checkoutLink} className="mb-2">
            <i className="fa fa-cart-plus"></i>
            {` `}Add to Cart
          </Button>
        ) : (
          <Button as={Link} to="/contact" className="mb-2">
            Contact Us
          </Button>
        )}
        <Button as={Link} to={shopUrl} variant="secondary">
          Learn More
        </Button>
      </Card.Body>
    </Card>
  )
}

export default Product
